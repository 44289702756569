import { React, useState, useEffect } from 'react';
import PageHeader from 'utility/PageHeader';
import PageBody from 'utility/PageBody';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Table,
  Modal,
  Typography,
  Select,
  Collapse,
  Switch,
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';
import { HttpHandler } from '../../shared/httpHandler';
import PropTypes from 'prop-types';
const { Paragraph } = Typography;
const httpHandler = new HttpHandler();

const locationTypeMap = {
  0: '全部',
  1: '服務廠',
  2: '保修站',
};
// 父組件
const ServiceLocation = () => {
  const [searchForm] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [dealerList, setDealerList] = useState([{ id: '0', name: '全部' }]);
  const [customLoungeList, setCustomLoungeList] = useState([]);
  const [dealerLock, setDealerLock] = useState(false);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    updateData(pagination);
  };

  const updateData = (pagination = tableParams.pagination) => {
    const { locationType, dealer, name } = searchForm.getFieldsValue();
    const { current, pageSize } = pagination;
    setLoading(true);
    httpHandler
      .get(
        `/service_location/list?name=${name}&location_type=${locationType}&dealer_id=${dealer}&page=${current}&page_size=${pageSize}&is_all=false`
      )
      .then((response) => {
        if (response.success) {
          setTableData(response.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...pagination,
              total: response.total,
            },
          });
        } else {
          window.antdNotify('錯誤', response.data.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      maskClosable: true, // 點擊蒙層是否允許關閉
      title: '確定刪除？',
      icon: <QuestionCircleOutlined />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn loading={deleteLoading} />
        </>
      ),
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        setDeleteLoading(true);
        return httpHandler
          .delete(`/service_location/${id}`)
          .then((response) => {
            if (response.success) {
              window.antdNotify('成功', '刪除成功', 'success');
              searchServiceLocation();
            } else {
              window.antdNotify('錯誤', response.data.message, 'fail');
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setDeleteLoading(false);
          });
      },
    });
  };

  const searchCustomLounge = async () => {
    return httpHandler
      .get('/custom_lounge/list?without_image=true&is_all=true')
      .then((response) => {
        if (response.success) {
          setCustomLoungeList(response.data);
        } else {
          window.antdNotify('錯誤', response.data.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchDealerList = async () => {
    return httpHandler
      .get('/dealer/list?name=&is_all=true')
      .then((response) => {
        if (response.success) {
          const opts = [{ id: '0', name: '全部' }, ...response.data];
          setDealerList(opts);
          checkDealerLock(opts);
        } else {
          window.antdNotify('錯誤', response.data.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 20241226 新增經銷商鎖定功能
  const checkDealerLock = (dealerOpts) => {
    setDealerLock(false);
    searchForm.setFieldsValue({
      dealer: '0',
    });

    // 使用目前登入者的「角色名稱 Role.name」進行判斷
    // 若角色名稱中有包含經銷商名稱，則將選項鎖定該經銷商
    const userRoleName = localStorage.getItem('userRoleName');
    if (userRoleName) {
      for (const dealer of dealerOpts) {
        if (userRoleName.includes(dealer.name)) {
          setDealerLock(true);
          searchForm.setFieldsValue({
            dealer: dealer.id,
          });
          break;
        }
      }
    }
  };

  const searchServiceLocation = async () => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    updateData({
      current: 1,
      pageSize: 10,
    });
  };

  const handleStatusChange = (statusName, checked, id, event) => {
    setLoading(true);
    const newStatus = checked ? 1 : 0;
    const data = {
      [statusName]: newStatus,
    };
    console.log(data);
    httpHandler
      .put(`/service_location/update_status/${id}`, data)
      .then((response) => {
        if (response.success) {
          updateData();
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
        window.antdNotify('錯誤', '更新狀態失敗', 'fail');
      });
  };

  // 進入畫面時搜尋據點
  useEffect(() => {
    searchForm.setFieldsValue({
      name: '',
      locationType: '0',
      dealer: '0',
    });

    setLoading(true);
    Promise.all([searchCustomLounge(), searchDealerList()]).finally(() => {
      searchServiceLocation();
    });
  }, []);

  // 搜尋組件
  const SearchBar = () => {
    return (
      <>
        <Form
          form={searchForm}
          name="searchForm"
          onFinish={searchServiceLocation}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="據點名稱" name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="據點類別" name="locationType">
                <Select>
                  {Object.keys(locationTypeMap).map((key) => (
                    <Select.Option key={key} value={key}>
                      {locationTypeMap[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="經銷商" name="dealer">
                <Select disabled={dealerLock}>
                  {dealerList.map((dealer) => (
                    <Select.Option key={dealer.id} value={dealer.id}>
                      {dealer.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16} className="button-group">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsNew(true);
                  setIsModalVisible(true);
                }}
                className="button button--register">
                新增
              </Button>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                className="button button--search">
                搜尋
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };
  // 表格組件
  const DataTable = () => {
    let columns = [
      {
        title: 'id',
        dataIndex: 'id',
        hidden: true,
      },
      {
        title: '據點名稱',
        dataIndex: 'name',
      },
      {
        title: '操作',
        dataIndex: '',
        render: (text, record, index) => {
          return (
            <>
              <Button
                icon={<EditOutlined />}
                type="primary"
                className="button--edit"
                style={{ marginRight: 16 }}
                onClick={() => {
                  setIsModalVisible(true);
                  setIsNew(false);
                  modalForm.setFieldsValue({
                    id: record.id,
                    name: record.name,
                    locationType: `${record.location_type}`,
                    locationCode: record.location_code,
                    address: record.address,
                    dealerId: record.dealer_id,
                    provideSnack: record.provide_snack,
                    provideAmenity: record.provide_amenity,
                    memo: record.memo,
                  });
                }}>
                修改
              </Button>
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                className="button--delete"
                onClick={() => {
                  handleDelete(record.id);
                }}>
                刪除
              </Button>
            </>
          );
        },
      },
      {
        title: '服務鈴',
        dataIndex: 'service_status',
        render: (value, row, index) => {
          return (
            <Switch
              checked={row.service_status === 1}
              checkedChildren={<>服務中</>}
              unCheckedChildren={<>暫停服務</>}
              onChange={(checked, event) =>
                handleStatusChange('service_status', checked, row.id, event)
              }
            />
          );
        },
      },
      {
        title: '點餐/備品',
        dataIndex: 'order_status',
        render: (value, row, index) => {
          return (
            <Switch
              checked={row.order_status === 1}
              checkedChildren={<>服務中</>}
              unCheckedChildren={<>暫停服務</>}
              onChange={(checked, event) =>
                handleStatusChange('order_status', checked, row.id, event)
              }
            />
          );
        },
      },
      {
        title: '據點類別',
        dataIndex: 'location_type',
        render: (text) => {
          return locationTypeMap[text];
        },
      },
      {
        title: '據點代碼',
        dataIndex: 'location_code',
      },
      {
        title: '地址',
        dataIndex: 'address',
      },
      {
        title: '經銷商',
        dataIndex: 'dealer_id',
        render: (text) => {
          return dealerList.find((dealer) => dealer.id === text)?.name;
        },
      },
      {
        title: '點心',
        dataIndex: 'provide_snack',
        render: (text) => {
          if (text.length > 0) {
            return (
              <Collapse
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}>
                <Collapse.Panel header="查看清單" key="1">
                  {text.map((item, index) => {
                    const snack = customLoungeList.find(
                      (snackItem) => snackItem.id === item
                    );
                    return <li key={index}>- {snack ? snack.name : item}</li>;
                  })}
                </Collapse.Panel>
              </Collapse>
            );
          }
          return '未提供點心';
        },
      },
      {
        title: '用品',
        dataIndex: 'provide_amenity',
        render: (text) => {
          if (text.length > 0) {
            return (
              <Collapse
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}>
                <Collapse.Panel header="查看清單" key="1">
                  {text.map((item, index) => {
                    const snack = customLoungeList.find(
                      (snackItem) => snackItem.id === item
                    );
                    return <li key={index}>- {snack ? snack.name : item}</li>;
                  })}
                </Collapse.Panel>
              </Collapse>
            );
          }
          return '未提供用品';
        },
      },
      {
        title: '修改時間',
        dataIndex: 'update_time',
      },
      {
        title: '修改人員',
        dataIndex: 'update_user',
      },
      {
        title: '建立時間',
        dataIndex: 'create_time',
      },
      {
        title: '建立人員',
        dataIndex: 'create_user',
      },
    ].filter((item) => !item.hidden);

    return (
      <>
        <Table
          columns={columns}
          rowKey={(row) => row.id}
          dataSource={tableData}
          pagination={tableParams.pagination}
          loading={loading}
          bordered="true"
          scroll={{ x: 'max-content' }}
          onChange={handleTableChange}
        />
      </>
    );
  };

  const section = [
    {
      hasTitle: false,
      title: 'Search',
      children: <SearchBar />,
    },
    {
      hasTitle: false,
      title: 'Data',
      children: <DataTable />,
    },
    {
      hasTitle: false,
      title: 'Modal',
      children: (
        <ServiceLocationModal
          searchServiceLocation={searchServiceLocation}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          modalForm={modalForm}
          isNew={isNew}
          dealerList={dealerList}
          customLoungeList={customLoungeList}
          tableData={tableData}
        />
      ),
    },
  ];

  return (
    <div>
      <PageHeader functionName={'service_location'}></PageHeader>
      <PageBody section={section}></PageBody>
    </div>
  );
};
// 共用的Modal
const ServiceLocationModal = (props) => {
  const {
    searchServiceLocation,
    isModalVisible,
    setIsModalVisible,
    modalForm,
    isNew,
    dealerList,
    customLoungeList,
    tableData,
  } = props;
  const [loading, setLoading] = useState(false);

  // 檢查據點代碼是否重複
  const ckeckLocationCodeIsExist = (values, isNew) => {
    const locationCode = values.locationCode;
    // 新增
    if (isNew === true) {
      const existData = tableData.find(
        (item) => item.location_code === locationCode
      );
      if (existData) {
        return true;
      }
    }
    // 修改
    else {
      const existData = tableData.find(
        (item) => item.location_code === locationCode && item.id !== values.id
      );
      if (existData) {
        return true;
      }
    }
    return false;
  };
  const handleModalSubmit = async () => {
    try {
      const values = await modalForm.validateFields();
      // 檢查據點代碼是否重複
      const codeExist = ckeckLocationCodeIsExist(values, isNew);
      if (codeExist) {
        window.antdNotify('錯誤', '已存在相同的據點代碼', 'fail');
        return;
      }
      const confirmed = await window.antdConfirm(
        `確定${isNew ? '新增' : '修改'}據點嗎？`
      );

      if (!confirmed) return;
      setLoading(true);
      // 根據isNew判斷是新增還是修改
      let requestPromise;
      const msg = isNew ? '新增成功' : '修改成功';
      var postData = {
        name: values.name,
        location_type: values.locationType,
        location_code: values.locationCode,
        address: btoa(encodeURIComponent(values.address)),
        dealer_id: values.dealerId,
        provide_snack: values.provideSnack,
        provide_amenity: values.provideAmenity,
        memo: btoa(encodeURIComponent(values.memo)),
      };
      if (isNew === true) {
        requestPromise = httpHandler.post('/service_location/', postData);
      } else {
        requestPromise = httpHandler.put(
          `/service_location/${values.id}`,
          postData
        );
      }
      requestPromise
        .then((response) => {
          if (response.success) {
            window.antdNotify('成功', msg, 'success');
            setIsModalVisible(false);
            searchServiceLocation();
            modalForm.resetFields();
          } else {
            window.antdNotify('錯誤', response.message, 'fail');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('error: ', error);
    }
  };
  const handleModalCancel = () => {
    modalForm.resetFields();
    setIsModalVisible(false);
  };
  return (
    <Modal
      title={isNew ? '新增據點' : '修改據點'}
      open={isModalVisible}
      onCancel={handleModalCancel}
      footer={[
        <Button key="back" onClick={handleModalCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalSubmit}
          loading={loading}>
          確定
        </Button>,
      ]}>
      <ModalForm
        modalForm={modalForm}
        dealerList={dealerList}
        customLoungeList={customLoungeList}
      />
    </Modal>
  );
};
// 共用Modal專用的Form(新增、修改共用)
const ModalForm = (props) => {
  const { modalForm, dealerList, customLoungeList } = props;
  // 提供點心、提供用品的全選
  const handleProvideListAllSelect = (value) => {
    if (value === 'allSnack') {
      const nowList = modalForm.getFieldValue('provideSnack');
      const snackList = customLoungeList.filter((item) => item.item_type === 1);
      // 如果全選了就清空, -1是要扣除"全選"這個選項
      if (nowList.length - 1 === snackList.length) {
        modalForm.setFieldsValue({ provideSnack: [] });
        // 如果沒全選就全選
      } else {
        const snackListId = snackList.map((item) => item.id);
        modalForm.setFieldsValue({ provideSnack: snackListId });
      }
    } else if (value === 'allAmenity') {
      const nowList = modalForm.getFieldValue('provideAmenity');
      const amenityList = customLoungeList.filter(
        (item) => item.item_type === 2
      );
      if (nowList.length - 1 === amenityList.length) {
        modalForm.setFieldsValue({ provideAmenity: [] });
      } else {
        const amenityListId = amenityList.map((item) => item.id);
        modalForm.setFieldsValue({ provideAmenity: amenityListId });
      }
    }
  };
  return (
    <Form form={modalForm}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label="據點名稱"
        name="name"
        rules={[{ required: true, message: '請輸入據點名稱' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="據點類別"
        name="locationType"
        rules={[{ required: true, message: '請選擇據點類別' }]}>
        <Select>
          {Object.keys(locationTypeMap)
            .filter((key) => key !== '0')
            .map((key) => (
              <Select.Option key={key} value={key}>
                {locationTypeMap[key]}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="據點代碼"
        name="locationCode"
        rules={[{ required: true, message: '請輸入據點代碼' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="地址"
        name="address"
        rules={[{ required: true, message: '請輸入地址' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="經銷商"
        name="dealerId"
        rules={[{ required: true, message: '請選擇經銷商' }]}>
        <Select>
          {dealerList
            .filter((dealer) => dealer.id !== '0')
            .map((dealer) => (
              <Select.Option key={dealer.id} value={dealer.id}>
                {dealer.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="提供點心" name="provideSnack">
        <Select
          mode="multiple"
          allowClear
          onSelect={(value) => handleProvideListAllSelect(value)}
          onDeselect={(value) => handleProvideListAllSelect(value)}>
          <Select.Option key="allSnack" value="allSnack">
            全部選擇
          </Select.Option>
          {customLoungeList
            .filter((item) => item.item_type == 1)
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="提供用品" name="provideAmenity">
        <Select
          mode="multiple"
          allowClear
          onSelect={(value) => handleProvideListAllSelect(value)}
          onDeselect={(value) => handleProvideListAllSelect(value)}>
          <Select.Option key="allAmenity" value="allAmenity">
            全部選擇
          </Select.Option>
          {customLoungeList
            .filter((item) => item.item_type == 2)
            .map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="備註" name="memo">
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};

// 驗證props
ServiceLocationModal.propTypes = {
  searchServiceLocation: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  modalForm: PropTypes.object,
};
ModalForm.propTypes = {
  modalForm: PropTypes.object,
};
export default ServiceLocation;
